function path(root: string, sublink: string, secondSublink?: string) {
  return `${root}${sublink}${secondSublink ? secondSublink : ""}`;
}

// ============================== Landing

export const ROOTS_LANDING = "";

export const PATH_LANDING = {
  index: path(ROOTS_LANDING, ""),
  home: path(ROOTS_LANDING, "/"),
  investor: path(ROOTS_LANDING, "/investor/"),
  fundraiser: path(ROOTS_LANDING, "/fundraiser/"),
  fundraiser_form: path(ROOTS_LANDING, "/fundraiser#form"),
  fundraiser_gads: path(ROOTS_LANDING, "/fundraiser/gads"),
  fundraiser_gads_form: path(ROOTS_LANDING, "/fundraiser/gads#form"),
  fundraiser_realestate: path(ROOTS_LANDING, "/fundraiser/realestate"),
  fundraiser_realestate_form: path(
    ROOTS_LANDING,
    "/fundraiser/realestate#form"
  ),
  fundraiserOther: path(ROOTS_LANDING, "/fundraiser/other"),
  fundraiserOther_form: path(ROOTS_LANDING, "/fundraiser/other#form"),
  blog: path(ROOTS_LANDING, "/blog/"),
  faq: path(ROOTS_LANDING, "/faq/"),
  about: path(ROOTS_LANDING, "/about/"),
  contact: path(ROOTS_LANDING, "/contact/"),
  opportunities: path(ROOTS_LANDING, "/opportunities/"),
  policy: path(ROOTS_LANDING, "/policy/"),
  privacypolicy: path(ROOTS_LANDING, "/privacypolicy/"),
  news: path(ROOTS_LANDING, "/news/"),
  cma: path(ROOTS_LANDING, "/cma/"),
  complaints: path(ROOTS_LANDING, "/complaints/")
};

// ================================================= Auth
