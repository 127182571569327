import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, Suspense, lazy } from "react";
import SEO from "../../components/HeadWrapper";
import { LanguageProvider } from "../../components/LanguageContext";
import { ModalProvider } from "../../contexts/ModalContext";
import { PATH_LANDING } from "../../paths";

const LandingNav = lazy(() => import("../../components/Landing/LandingNavBar"));
const LandingFooter = lazy(() => import("../../components/Landing/LandingFooter"));

export default function LandingLayoutContainer(props: {
  children: React.ReactNode;
  pageContext: { lang: string };
}) {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      sanityNewsPage {
        title {
          en
          ar
        }
      }
      sanityBlogPage {
        title {
          en
          ar
        }
      }
      sanityHome {
        title {
          ar
          en
        }
      }
      sanityAbout {
        title {
          ar
          en
        }
      }
      sanityInvestorGuide {
        title {
          ar
          en
        }
      }
      sanityFundraiserGuide {
        title {
          ar
          en
        }
      }
      sanityPrivacyAndPolicy {
        title {
          ar
          en
        }
      }
      sanityConditionsAndTerms {
        title {
          ar
          en
        }
      }
      sanityContactUs {
        title {
          ar
          en
        }
      }
      sanityComplaints {
        title {
          ar
          en
        }
      }
      sanityFaqPage {
        title {
          ar
          en
        }
      }
      sanityFooter {
        title {
          ar
          en
        }
        footerTitle {
          ar
          en
        }
        footerSubTitle {
          ar
          en
        }
        twitter
        linkedin
        instagram
        tiktok
        email
        mobileNo
        location {
          ar
          en
        }
        warning {
          description {
            ar
            en
          }
          title {
            ar
            en
          }
        }
        disclosures {
          description {
            ar
            en
          }
          title {
            ar
            en
          }
        }
      }
    }
  `);

  return (
    <LanguageProvider>
      <LandingLayout {...props} data={data} />
    </LanguageProvider>
  );
}

function LandingLayout({
  children,
  outsider,
  margin = " mb-28",
  data,
}: {
  children: React.ReactNode;
  outsider?: React.ReactNode;
  margin?: string;
  data: any;
}) {

  const location = useLocation();

  useEffect(() => {
    if (!location.href?.includes('#')) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <>
      {/* <SEO /> */}
      <ModalProvider>
        <Suspense fallback={<></>}>
          <LandingNav data={data} />
        </Suspense>
        <div
          className={` ${location.pathname === PATH_LANDING.home ? "" : " "
            }  allToleft z-20`}
        >
          {children}
        </div>
        <span className={` w-full flex ${margin} `} />
        {outsider}
        <Suspense fallback={<></>}>
          <LandingFooter data={data} />
        </Suspense>
      </ModalProvider>
    </>
  );
}
