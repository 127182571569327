import React from "react";
import Layout from "./src/layouts/landingLayout";
import './src/styles/global.css';


export const wrapPageElement = ({ element, props }) => {

    return (
        <Layout {...props} pathname={props.location.pathname} home={props.location.pathname === '/' ? true : false}>
            {element}
        </Layout>
    )
}