import { useLocation } from "@reach/router";
import React, { createContext, ReactNode, useReducer } from "react";
import { ActionMap } from "../@types/ActionMap";
import { navigate } from "gatsby";

// ----------------------------------------------------------------------

export type LanguageType = {
  children?: React.ReactNode;
  title: string;
  switchLanguage?: () => Promise<void | boolean> | void | boolean;
  isArabic: boolean;
};

type LanguageState = {
  isArabic: boolean;
  children: React.ReactNode;
};

enum Types {
  SWITCH = "SWITCH",
}

type LanguagePayload = {
  [Types.SWITCH]: undefined;
};

export type LanguageActions =
  ActionMap<LanguagePayload>[keyof ActionMap<LanguagePayload>];

const LanguageReducer = (
  state: Partial<LanguageState>,
  action: LanguageActions
) => {
  switch (action.type) {
    case Types.SWITCH:
      const newIsArabic = !state.isArabic;
      typeof window !== "undefined" &&
        window?.localStorage.setItem("isArabic", `${newIsArabic}`);

      // Get current path and handle language switch navigation
      const pathname = typeof window !== "undefined" ? window.location.pathname : "";
      const currentPath = pathname.replace(/^\/(en\/)?/, ""); // Remove language prefix if exists

      // Navigate to new language path
      if (newIsArabic) {
        navigate(`/${currentPath}`);
      } else {
        navigate(`/en/${currentPath}`);
      }

      return {
        ...state,
        isArabic: newIsArabic,
      };

    default:
      return state;
  }
};

type LanguageContextType = {
  switchLanguage: () => Promise<void> | void;
  isArabic?: boolean;
};

const LanguageContext = createContext<LanguageContextType | null>(null);

// ----------------------------------------------------------------------

type LanguageProviderProps = {
  children: ReactNode;
};
const initialState: LanguageState = {
  // isArabic:  typeof window !== 'undefined' && window?.localStorage.getItem("isArabic") === "true" ? true : false,
  isArabic: true,
  children: <></>,
};

const arabicPaths = ["fa", "fe", "fu", "fh", "fs"];
const englishPaths = [
  // "fa",
  "fe",
  "fu",
  "fh",
  "fs",
  // "la",
  "le",
  "lu",
  "lh",
  "ls",
  // "ga",
  "ge",
  "gu",
  "gh",
  "gs",
];

function LanguageProvider({ children }: LanguageProviderProps) {
  const location = useLocation();

  const isEnglish = location.pathname.startsWith("/en");

  const [state, dispatch] = useReducer(LanguageReducer, {
    ...initialState,
    isArabic: !isEnglish,
  });

  const switchLanguage = async () => {
    dispatch({
      type: Types.SWITCH,
    });
  };

  return (
    <LanguageContext.Provider
      value={{
        ...state,
        switchLanguage,
        isArabic: state.isArabic,
      }}
    >
      <div
        style={{
          direction: !state.isArabic ? "ltr" : "rtl",
          fontFamily: !state.isArabic ? "Nexa" : "Neue",
        }}
        className={` flex flex-col w-full `}
      >
        {children}
      </div>
    </LanguageContext.Provider>
  );
}

export { LanguageContext, LanguageProvider };
