exports.components = {
  "component---src-components-landing-landing-article-index-tsx": () => import("./../../../src/components/Landing/LandingArticle/index.tsx" /* webpackChunkName: "component---src-components-landing-landing-article-index-tsx" */),
  "component---src-components-landing-landing-news-landing-news-article-tsx": () => import("./../../../src/components/Landing/LandingNews/LandingNewsArticle.tsx" /* webpackChunkName: "component---src-components-landing-landing-news-landing-news-article-tsx" */),
  "component---src-pages-lang-code-404-tsx": () => import("./../../../src/pages/{Lang.code}/404.tsx" /* webpackChunkName: "component---src-pages-lang-code-404-tsx" */),
  "component---src-pages-lang-code-about-tsx": () => import("./../../../src/pages/{Lang.code}/about.tsx" /* webpackChunkName: "component---src-pages-lang-code-about-tsx" */),
  "component---src-pages-lang-code-blog-index-tsx": () => import("./../../../src/pages/{Lang.code}/blog/index.tsx" /* webpackChunkName: "component---src-pages-lang-code-blog-index-tsx" */),
  "component---src-pages-lang-code-cma-tsx": () => import("./../../../src/pages/{Lang.code}/cma.tsx" /* webpackChunkName: "component---src-pages-lang-code-cma-tsx" */),
  "component---src-pages-lang-code-complaints-tsx": () => import("./../../../src/pages/{Lang.code}/complaints.tsx" /* webpackChunkName: "component---src-pages-lang-code-complaints-tsx" */),
  "component---src-pages-lang-code-contact-tsx": () => import("./../../../src/pages/{Lang.code}/contact.tsx" /* webpackChunkName: "component---src-pages-lang-code-contact-tsx" */),
  "component---src-pages-lang-code-faq-tsx": () => import("./../../../src/pages/{Lang.code}/faq.tsx" /* webpackChunkName: "component---src-pages-lang-code-faq-tsx" */),
  "component---src-pages-lang-code-fundraiser-campaign-tsx": () => import("./../../../src/pages/{Lang.code}/fundraiser/campaign.tsx" /* webpackChunkName: "component---src-pages-lang-code-fundraiser-campaign-tsx" */),
  "component---src-pages-lang-code-fundraiser-gads-index-tsx": () => import("./../../../src/pages/{Lang.code}/fundraiser/gads/index.tsx" /* webpackChunkName: "component---src-pages-lang-code-fundraiser-gads-index-tsx" */),
  "component---src-pages-lang-code-fundraiser-index-tsx": () => import("./../../../src/pages/{Lang.code}/fundraiser/index.tsx" /* webpackChunkName: "component---src-pages-lang-code-fundraiser-index-tsx" */),
  "component---src-pages-lang-code-fundraiser-other-campaign-tsx": () => import("./../../../src/pages/{Lang.code}/fundraiser/other/campaign.tsx" /* webpackChunkName: "component---src-pages-lang-code-fundraiser-other-campaign-tsx" */),
  "component---src-pages-lang-code-fundraiser-other-index-tsx": () => import("./../../../src/pages/{Lang.code}/fundraiser/other/index.tsx" /* webpackChunkName: "component---src-pages-lang-code-fundraiser-other-index-tsx" */),
  "component---src-pages-lang-code-gads-tsx": () => import("./../../../src/pages/{Lang.code}/gads.tsx" /* webpackChunkName: "component---src-pages-lang-code-gads-tsx" */),
  "component---src-pages-lang-code-index-tsx": () => import("./../../../src/pages/{Lang.code}/index.tsx" /* webpackChunkName: "component---src-pages-lang-code-index-tsx" */),
  "component---src-pages-lang-code-investor-tsx": () => import("./../../../src/pages/{Lang.code}/investor.tsx" /* webpackChunkName: "component---src-pages-lang-code-investor-tsx" */),
  "component---src-pages-lang-code-leap-2024-full-tsx": () => import("./../../../src/pages/{Lang.code}/leap2024/full.tsx" /* webpackChunkName: "component---src-pages-lang-code-leap-2024-full-tsx" */),
  "component---src-pages-lang-code-leap-2024-index-tsx": () => import("./../../../src/pages/{Lang.code}/leap2024/index.tsx" /* webpackChunkName: "component---src-pages-lang-code-leap-2024-index-tsx" */),
  "component---src-pages-lang-code-news-index-tsx": () => import("./../../../src/pages/{Lang.code}/news/index.tsx" /* webpackChunkName: "component---src-pages-lang-code-news-index-tsx" */),
  "component---src-pages-lang-code-policy-tsx": () => import("./../../../src/pages/{Lang.code}/policy.tsx" /* webpackChunkName: "component---src-pages-lang-code-policy-tsx" */),
  "component---src-pages-lang-code-privacypolicy-tsx": () => import("./../../../src/pages/{Lang.code}/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-lang-code-privacypolicy-tsx" */),
  "component---src-templates-form-page-tsx": () => import("./../../../src/templates/FormPage.tsx" /* webpackChunkName: "component---src-templates-form-page-tsx" */)
}

